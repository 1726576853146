<template>
    <div class="products">
       <!--- Modals --->
        <itc-modal :showModal="showModal.addCategory">
            <div slot="header">
                <h4>Añadir categoría</h4>
            </div>
            <div slot="body">
                <label>Nombre de la categoría</label>
                <input v-model="newCatShowName" type="text" class="form-control">
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="addNewCategory">OK</button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.addCategory = false">Cerrar</button>
                    </div>
                </div>
            </div>
        </itc-modal>
        <itc-modal :showModal="showModal.subCategory">
            <div slot="header">
                <h4>{{header}}</h4>
            </div>
            <div slot="body">
                <div class="row">
                    <div class="col-12">
                        <label>Nombre de la sub-categoría</label>
                        <input v-model="newSubCatShowName" type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="processSubmitSubCategory">OK</button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.subCategory = false">Cerrar</button>
                    </div>
                </div>
            </div>
        </itc-modal>
        <!--- / Modals --->
        <h4 class="pt-3">Categorías<button @click="showModal.addCategory = true" class="mx-2 btn btn-success btn-sm"><i class="fas fa-plus"></i></button></h4>
        <hr>
        <itc-card color="#343a40" class="custom-shadow w-50 align-div">
            <div slot="header" class="text-white text-center">
                <h4>Seleccionar categoría</h4>
            </div>
            <div slot="body" class=" w-50 align-div">
                <select v-model="category" class="form-control">
                    <option v-for="(category, i) in categories" :key="i" :value="category">{{category.showName}}</option>
                </select>
            </div>
        </itc-card>
        <transition name="fade" mode="out-in">
            <div v-if="category.subCategories" class="table-resposive my-3 custom-shadow">
                <table class="table text-center">
                    <thead class="thead-dark">
                        <tr>
                            <th>Sub-categorías</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="category.subCategories.length == 0" colspan="2" class="text-center">
                            <h5 class="p-4">Aún no hay sub-categorías para esta categoría</h5>
                        </tr>
                        <tr 
                            v-for="(subCategory, i) in category.subCategories" :key="i" class="category-item set-pointer"
                            @click="subCategoryModal(subCategory, 'Editar sub-categoría')">
                            <td>{{subCategory.showName}}</td>
                        </tr>
                        <tr>
                            <td class="add-fee">
                                <button @click="subCategoryModal(null, 'Añadir sub-categoría')" class="btn btn-info float-right">Añadir</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> 
        </transition>
    </div>
</template>

<script>
import axios from 'axios'

import * as utils from '@/assets/utils/utils.js'

import Modal from '@/components/mix/Modal'
import Card from '@/components/mix/Card'

export default {
    data(){
        return {
            showModal: {addCategory: false, subCategory: false},
            header: '',
            categories: [],
            category: {},
            subCategoryToEdit: {},
            newSubCatShowName: '',
            newCatShowName: ''
        }
    },
    methods: {
        async addNewCategory(){
            if(this.newCatShowName.length > 0){
                const resp = await axios.post('/category/add', {showName: this.newCatShowName});
                this.category = resp.data;
                this.category.subCategories = []
                this.showModal.addCategory = false;
                const index = utils.getInsertionIndex(this.categories, this.newCatShowName, utils.compareCategoryNames)
                this.categories.splice(index, 0, this.category)
            }
        },
        async processSubmitSubCategory(){
            if(this.subCategoryToEdit){
                const findSubCat = subCat => subCat._id == this.subCategoryToEdit._id
                const index = this.category.subCategories.findIndex(findSubCat)
                this.category.subCategories.splice(index, 1)
            } 
            const resp = await axios.post(`/category/${this.subCategoryToEdit ? 's-update' : 's-add'}`, {
                categoryId: this.category._id,
                subCatId: this.subCategoryToEdit ? this.subCategoryToEdit._id : null,
                showName: this.newSubCatShowName
            })
            var insertionIndex = utils.getInsertionIndex(this.category.subCategories, this.newSubCatShowName, utils.compareCategoryNames)
            const subCategory = resp.data
            this.category.subCategories.splice(insertionIndex, 0, subCategory)
            this.showModal.subCategory = false
        },
        subCategoryModal(subCategory, header){
            this.subCategoryToEdit = subCategory,
            this.newSubCatShowName = subCategory ? subCategory.showName : ''
            this.header = header
            this.showModal.subCategory = true 
        }
    },
    components: {
        'itc-card': Card,
        'itc-modal': Modal
    },
    async created(){
        const resp = await axios.get('/category/complete');
        this.categories = resp.data
    }   
}
</script>

<style lang="scss" scoped>
.add-fee{
    background-color: #343a40;
}
.category-item:hover{
    background-color: lightgreen;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>